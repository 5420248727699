import React, { useState, useRef, useEffect } from 'react';
import { Breadcrumb, Button, Modal } from 'react-bootstrap';
import { ErrorNotify, imagePath, infoNotify, successNotify } from '../../CommonLogic';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as AuthSelector from '../../store/auth/selectors';
import { useNavigate } from 'react-router-dom';
import { client } from '../../services/apiService'

const OrganisationFinance = () => {
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const organisation = useSelector(AuthSelector.organization);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    // States for organisations
    const [organisationOriginalList, setorganisationOriginalList] = useState([]); // Original organisation list
    const [organisationList, setorganisationList] = useState([]); // Original organisation list
    const [organisationFilteredList, setorganisationFilteredList] = useState([]); // Filtered organisation list

    // States for departments
    const [departmentOriginalList, setDepartmentOriginalList] = useState({}); // Original department list
    const [departmentFilteredList, setDepartmentFilteredList] = useState({}); // Filtered department list

    const [searchString, setSearchString] = useState(''); // Search input
    const isOrganisation = useRef(true); // Flag to determine search type (organisation or department)
    const isDepartment = useRef(false); // Flag to determine search type (organisation or department)
    const parentId = useRef(null); // Flag to determine (organisation or department) id
    const organisationId = useRef(null); // Flag to determine organisation id
    const apiLoader = useRef(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        url: '',
        industry: '',
        pocName: '',
        pocContact: '',
    });

    // State for warnings and validation
    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        url: '',
        contact: '',
        form: '',
    });

    useEffect(() => {
        fetchOrganisationList(); // Fetch the organisation data initially
    }, [])

    // Fetch all organisations and update both original and filtered lists
    async function fetchOrganisationList() {
        try {
            const response = await client.get('/organisations?populate=Departments');
            if (response.success) {
                setorganisationOriginalList(response.data); // Store original organisation data
                setorganisationFilteredList(response.data); // Initialize filtered list
                setorganisationList(response.data);
                parentId.current = null;
                isOrganisation.current = true;
                isDepartment.current = false;
            }
        } catch (error) {
            ErrorNotify('Error fetching organisations');
        }
    }

    // Fetch department list based on a department ID (drill-down)
    async function fetchDepartmentList(departmentId) {
        if (!departmentId) {
            infoNotify("Invalid department ID");
            return;
        }
        isOrganisation.current = false;
        try {
            const response = await client.get(`/departments/${departmentId}?populate=Departments&populate=Departments.Departments`);
            if (response.success) {
                setDepartmentOriginalList(response.data); // Store original department data
                setDepartmentFilteredList(response.data); // Initialize filtered list
            }
        } catch (error) {
            ErrorNotify('Error fetching departments');
        }
    }

    // Search organisations or departments based on search input
    function SearchEntity() {
        if (searchString.trim() === '') {
            // If search input is empty, reset the filtered list to the original list
            if (isOrganisation.current) {
                setorganisationFilteredList(organisationOriginalList);
            } else {
                setDepartmentFilteredList(departmentOriginalList);
            }
            return;
        }

        // Filter organisations or departments based on the search input
        if (isOrganisation.current) {
            const filteredorganisations = organisationOriginalList.filter((org) =>
                org.attributes?.Organisation_Name?.toLowerCase().includes(searchString.toLowerCase()) || org.attributes?.Department_Name?.toLowerCase().includes(searchString.toLowerCase())
            );
            setorganisationFilteredList(filteredorganisations); // Update the filtered state
        } else {
            const filteredDepartments = JSON.parse(JSON.stringify(departmentOriginalList)); // Create a shallow copy

            // Filter the departments within the original object
            const departmentData = filteredDepartments.attributes.Departments.data;

            // Filter the departments based on the search string
            filteredDepartments.attributes.Departments.data = departmentData.filter((department) =>
                department.attributes?.Department_Name.toLowerCase().includes(searchString.toLowerCase())
            );

            // Only update the state if there are any filtered departments
            if (filteredDepartments.attributes.Departments.data.length > 0) {
                setDepartmentFilteredList(filteredDepartments); // Update the filtered state
            } else {
                setDepartmentFilteredList({}); // Reset if no departments match
            }
        }
    }

    // Updating 2 State of organisation Child Department
    function updateOrganisationState(Department) {
        setorganisationFilteredList(Department);
        setorganisationOriginalList(Department);
        isDepartment.current = true;
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setFormErrors({
            name: '',
            email: '',
            url: '',
            pocContact: '',
            form: '',
        });
        setFormData({
            name: '',
            email: '',
            url: '',
            industry: '',
            pocName: '',
            pocContact: '',
        });
    };

    const handleShowModal = () => setShowModal(true);

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validateUrl = (url) => /^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/.test(url);
    const validateContact = (contact) => /^\+?[0-9]+$/.test(contact);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleAddOrganisation = async () => {
        if (apiLoader.current) {
            return
        }
        const { name, email, url, industry, pocName, pocContact } = formData;

        // Check if all fields are filled
        if (!name || !email || !url || !industry || !pocName || !pocContact) {
            setFormErrors((prev) => ({ ...prev, form: 'Please fill all mandatory fields.' }));
            return;
        }

        // Validate email
        if (!validateEmail(email)) {
            setFormErrors((prev) => ({ ...prev, email: 'Please enter a valid email address.' }));
            return;
        }

        // Validate URL
        if (!validateUrl(url)) {
            setFormErrors((prev) => ({ ...prev, url: 'Please enter a valid URL (starting with http:// or https://).' }));
            return;
        }

        // Validate contact
        if (!validateContact(pocContact)) {
            setFormErrors((prev) => ({ ...prev, pocContact: 'Please enter a valid contact number.' }));
            return;
        }

        const organisation = isOrganisation.current;
        const subOrganisation = isOrganisation.current && isDepartment.current;
        const department = isDepartment.current;
        const departmentType = checkDepartmentType(organisation, subOrganisation, department)

        if (departmentType == 'Organisation') {
            // Check if the organization name already exists
            if (organisationList.some(org => org.attributes?.Organisation_Name?.toLowerCase() === name.toLowerCase())) {
                setFormErrors((prev) => ({ ...prev, name: 'Organization name already exists.' }));
                return;
            }
        } else if (departmentType == 'subOrganisation') {
            // Check if the subOrganization name already exists
            if (organisationOriginalList.some(org => org.attributes?.Department_Name?.toLowerCase() === name.toLowerCase())) {
                setFormErrors((prev) => ({ ...prev, name: 'subOrganization name already exists.' }));
                return;
            }
        } else if (departmentType == 'Department') {
            // Check if the department name already exists
            if (departmentOriginalList.attributes?.Departments?.data.some(org => org.attributes?.Department_Name?.toLowerCase() === name.toLowerCase())) {
                setFormErrors((prev) => ({ ...prev, name: 'Department name already exists.' }));
                return;
            }
        }

        // Clear errors and proceed with form submission logic
        setFormErrors({
            name: '',
            email: '',
            url: '',
            contact: '',
            form: '',
        });

        const organisationPayload = {
            data: {
                Organisation_Name: name,
                Work_Email: email,
                Url: url,
                Industry: industry,
                Client_POC_Name: pocName,
                Client_POC_Contact: pocContact,
            },
            parentId: parentId.current,
            identity: departmentType
        }
        const departmentPayload = {
            data: {
                Department_Name: name,
                Work_Email: email,
                Url: url,
                Industry: industry,
                Client_POC_Name: pocName,
                Client_POC_Contact: pocContact,
                Organisation: {
                    connect: [
                        {
                            id: organisationId.current,
                        },
                    ],
                }
            },
            parentId: parentId.current,
            identity: departmentType,
        }

        try {
            apiLoader.current = true;
            const response = await client.post('/organisations/profile', departmentType == 'Organisation' ? organisationPayload : departmentPayload);
            if (response.success) {
                successNotify(`${departmentType} added successfully!`);
                handleCloseModal();
                fetchOrganisationList();
            }
            else {
                throw response
            }
        } catch (error) {
            ErrorNotify(`Failed to add ${departmentType}.`);
            apiLoader.current = false;
        }
    };

    function checkDepartmentType(org, subOrg, Dept) {
        if (org && !subOrg && !Dept) {
            return 'Organisation'
        } else if (org && subOrg && Dept) {
            return 'subOrganisation'
        } else if (!org && !subOrg && Dept) {
            return 'Department'
        }
        else return ''

    }
    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className=''>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='breadcrumb_box'>
                                                <Breadcrumb>
                                                    <Breadcrumb.Item
                                                        as='li'
                                                        href='/'
                                                    >
                                                        <img
                                                            src={imagePath(
                                                                '/images/home_icon_pagig.svg'
                                                            )}
                                                            alt=''
                                                        />
                                                    </Breadcrumb.Item>
                                                    <Breadcrumb.Item
                                                        as='li'
                                                        aria-current='page'
                                                        onClick={() => { navigate(`/o/${orgStrId}/setup`) }}
                                                    >
                                                        Settings
                                                    </Breadcrumb.Item>
                                                    <Breadcrumb.Item
                                                        as='li'
                                                        active
                                                        aria-current='page'
                                                    >
                                                        Organisations
                                                    </Breadcrumb.Item>
                                                </Breadcrumb>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='page_title_box two_column_title'>
                                        <h3 className='page_title'>
                                            <span className='pe-3'>
                                                <button className='btn btn_transparent'>
                                                    <img
                                                        onClick={() => { navigate(`/o/${orgStrId}/setup`) }}
                                                        src='/images/back_arrow.svg'
                                                        alt='back button'
                                                    />
                                                </button>
                                            </span>{' '}
                                            Organisations
                                        </h3>
                                        <button
                                            className='btn comman_btn'
                                            onClick={() => {
                                                handleShowModal();
                                            }}
                                        >
                                            {!isDepartment.current ? "Add Organisation" : "Add Department"}
                                        </button>
                                    </div>
                                </div>
                                {/* search */}
                                <div className='d-flex justify-content-end'>
                                    <div className='useronboard_search ms-3'>
                                        <input
                                            className='form-control'
                                            type='text'
                                            placeholder={isOrganisation.current ? "Search Organisation" : "Search Department"}
                                            value={searchString}
                                            onChange={(e) => setSearchString(e.target.value)}
                                        />
                                        <button className='diy_emp_search_btn' onClick={SearchEntity}>
                                            <i
                                                className='fa fa-search'
                                                aria-hidden='true'
                                            ></i>
                                        </button>
                                    </div>
                                </div>

                                <div className='table_style_comman mt-3'>
                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Sl. no</th>
                                                    <th>Name</th>
                                                    <th>Work Email</th>
                                                    <th>URL</th>
                                                    <th>Industry</th>
                                                    <th>POC Name</th>
                                                    <th>POC Contact</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Mapping organisations or departments based on the current view */}
                                                {isOrganisation.current ? (
                                                    organisationFilteredList.map((org, i) => (
                                                        <tr key={`organisationList ${i + 1}`}>
                                                            <td>{i + 1}</td>
                                                            <td className='link_underline' onClick={() => {
                                                                parentId.current = org.id;
                                                                if (isOrganisation.current && !(isOrganisation.current && isDepartment.current)) { organisationId.current = org.id; }
                                                                org?.attributes?.Departments?.data?.length == 0 ? updateOrganisationState([]) : org?.attributes?.Departments?.data?.length > 0 ? updateOrganisationState(org?.attributes?.Departments?.data) : fetchDepartmentList(org?.id);
                                                            }}>
                                                                {org?.attributes?.Organisation_Name ? org?.attributes?.Organisation_Name : org.attributes?.Department_Name ? org.attributes?.Department_Name : 'Not Found'}
                                                            </td>
                                                            <td>{org?.attributes?.Work_Email ? org?.attributes?.Work_Email : 'Email not added'}</td>
                                                            <td>{org?.attributes?.Url ? org?.attributes?.Url : 'URL not added'}</td>
                                                            <td>{org?.attributes?.Industry ? org?.attributes?.Industry : 'Industry not added'}</td>
                                                            <td>{org.attributes?.Client_POC_Name ? org.attributes?.Client_POC_Name : 'Name not added'}</td>
                                                            <td>{org.attributes?.Client_POC_Contact ? org.attributes?.Client_POC_Contact : 'Contact not added'}</td>
                                                            <td>
                                                                <Link to={`/o/${orgStrId}/setup/organisation-profile/${isOrganisation.current && !(isOrganisation.current && isDepartment.current) ? 'org-' + org?.id : 'dept-' + org?.id}`} className='link_text fw_600'>View</Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    departmentFilteredList?.attributes?.Departments?.data?.map((dept, i) => (
                                                        <tr key={`DepartmentList ${i + 1}`}>
                                                            <td>{i + 1}</td>
                                                            <td className='link_underline' onClick={() => { parentId.current = dept.id; fetchDepartmentList(dept?.id); }}>
                                                                {dept?.attributes?.Department_Name || 'Department Not Found'}
                                                            </td>
                                                            <td>{dept?.attributes?.Work_Email ? dept?.attributes?.Work_Email : 'Email not added'}</td>
                                                            <td>{dept?.attributes?.Url ? dept?.attributes?.Url : 'URL not added'}</td>
                                                            <td>{dept?.attributes?.Industry ? dept?.attributes?.Industry : 'Industry not added'}</td>
                                                            <td>{dept?.attributes?.Client_POC_Name ? dept?.attributes?.Client_POC_Name : 'Name not added'}</td>
                                                            <td>{dept?.attributes?.Client_POC_Contact ? dept?.attributes?.Client_POC_Contact : 'Contact not added'}</td>
                                                            <td>
                                                                <Link to={`/o/${orgStrId}/setup/organisation-profile/dept-${dept?.id}`} className='link_text fw_600'>View</Link>
                                                            </td>

                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop='static'
                keyboard={false}
                className='edit_plan_modal'
            >
                <Modal.Header>
                    <h4 className='text-primary'>Add Organisation</h4>
                </Modal.Header>
                <Modal.Body>
                    <form className='form_style_comman'>
                        <div className='row'>
                            {[
                                { label: 'Name', name: 'name', required: true },
                                { label: 'Work Email', name: 'email', required: true, type: 'email' },
                                { label: 'URL', name: 'url', required: true },
                                { label: 'Industry', name: 'industry', required: true },
                                { label: 'POC Name', name: 'pocName', required: true },
                                { label: 'POC Contact', name: 'pocContact', required: true },
                            ].map(({ label, name, required, type = 'text' }) => (
                                <div key={name} className='col-lg-4 form-group'>
                                    <label>
                                        {label}
                                        {required && <sup className='sup_manatory'>*</sup>}
                                    </label>
                                    <input
                                        type={type}
                                        className='form-control'
                                        name={name}
                                        placeholder={label}
                                        value={formData[name]}
                                        onChange={handleInputChange}
                                    />
                                    {formErrors[name] && <small className='text-danger'>{formErrors[name]}</small>}
                                </div>
                            ))}
                            <div className='text-end mt-3 col-12'>
                                {formErrors.form && <small className='text-danger'>{formErrors.form}</small>}
                                <Button className='btn bordered_btn me-3' onClick={handleCloseModal}>
                                    Discard
                                </Button>
                                <Button variant='primary' type='button' onClick={handleAddOrganisation}>
                                    Add
                                </Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default OrganisationFinance;
